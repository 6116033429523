import React from "react";
import img from "../images/waves.png";
import styled from "styled-components";

const WavesWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center right;
  z-index: -1;

  @media only screen and (max-width: 768px) {
    background-position: 75vw center;
  }
`;

const Waves = () => {
  return <WavesWrapper />;
};

export default Waves;
