import React from "react";
import styled from "styled-components";

interface Props {
  size?: string;
  color?: string;
}
const IconWrapper = styled("svg")`
  position: relative;
`;

const IconEmail = ({ color = "inherit", size = "24px" }: Props) => (
  <IconWrapper
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    fill={color}
    viewBox="0 0 88 60"
  >
    <path d="M9-.002c-4.93 0-9 4.07-9 9v42c0 4.93 4.07 9 9 9h70c4.93 0 9-4.07 9-9v-42c0-4.93-4.07-9-9-9H9zm.66 6h68.62l-31.97 28.03c-1.54 1.35-3.15 1.38-4.69.03L9.66 5.998zM82 10.718v40.28c0 1.71-1.29 3-3 3H9c-1.71 0-3-1.29-3-3v-40.25l31.66 27.81c3.59 3.15 9.04 3.15 12.62 0L82 10.718z" />
  </IconWrapper>
);

export default IconEmail;
