import React from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";
import Blurb from "../components/blurb";

const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-bottom: 60px;

  fieldset {
    border: none;
  }

  legend {
    text-transform: uppercase;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const StyledInput = styled("input")`
  appearance: none;
  display: flex;
  width: 100%;
  border: 0;
  outline: none;
  border-bottom: 1px solid #e3e3e3;
  padding: 16px 0;
  box-sizing: border-box;
  margin: 0 0 12px 0;
  font-family: var(--font);
  border-radius: 0px;
  font-size: 1em;
  background: transparent;

  &:active,
  &:focus {
    border-bottom: 1px solid var(--primary);
  }
`;

const Button = styled("input")`
  appearance: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 0 0;

  border: 1px solid transparent;
  color: white;
  background: #84a4d1;
  padding: 12px 16px;
  border-radius: 3px;
  font-size: 1em;
  cursor: pointer;
  transition: all 80ms ease;
  text-transform: uppercase;
  font-family: "Caudex", sans-serif;
  letter-spacing: 0.04em;

  &:hover,
  &:active,
  &:focus {
    background: var(--highlight-hover);
    color: var(--primary);
  }
`;

const ErrorWrapper = styled.div`
  color: red;
  font-size: 0.8em;
  margin-top: 24px;
`;

export default class SignUp extends React.Component {
  state = {
    name: null,
    email: null,
    error: null,
    success: false
  };

  _handleChange = e => {
    this.setState({
      [`${e.target.name}`]: e.target.value,
      error: null
    });
  };

  _handleSubmit = e => {
    e.preventDefault();

    addToMailchimp(this.state.email, this.state)
      .then(({ msg, result }) => {
        if (result !== "success") {
          throw msg;
        }
        this.setState({
          success: true
        });
      })
      .catch(error => {
        this.setState({
          error: error.message
        });
      });
  };

  render() {
    if (this.state.success) {
      return (
        <div>
          Thank you. All done. <br />
          You'll get an email soon asking you to confirm your sign up.
        </div>
      );
    }

    return (
      <div>
        <Blurb />
        <StyledForm onSubmit={this._handleSubmit}>
          <fieldset>
            <legend>
              <h3>Sign up for studio news</h3>
            </legend>

            <StyledInput
              type="text"
              onChange={this._handleChange}
              placeholder="Name"
              name="name"
            />
            <StyledInput
              type="email"
              onChange={this._handleChange}
              placeholder="Email address"
              name="email"
            />
            {this.state.error && (
              <ErrorWrapper>
                Hmm that didn't work.
                <br /> This might be because you've already signed up.{" "}
              </ErrorWrapper>
            )}
            <div className="mt-l">
              <Button type="submit" value="Sign up" />
            </div>
          </fieldset>
        </StyledForm>
      </div>
    );
  }
}
