import React from "react";
import styled from "styled-components";

interface Props {
  size?: string;
  color?: string;
}
const IconWrapper = styled("svg")`
  position: relative;
`;

const IconInsta = ({ color = "inherit", size = "24px" }: Props) => (
  <IconWrapper
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 503.84 503.84"
    width={size}
    fill={color}
  >
    <path d="M251.92 45.39c67.27 0 75.23.26 101.8 1.47 24.56 1.12 37.9 5.22 46.78 8.67 11.76 4.57 20.15 10.03 28.97 18.84 8.82 8.82 14.28 17.21 18.84 28.97 3.45 8.88 7.55 22.22 8.67 46.78 1.21 26.56 1.47 34.53 1.47 101.8s-.26 75.23-1.47 101.8c-1.12 24.56-5.22 37.9-8.67 46.78-4.57 11.76-10.03 20.15-18.84 28.97-8.82 8.82-17.21 14.28-28.97 18.84-8.88 3.45-22.22 7.55-46.78 8.67-26.56 1.21-34.53 1.47-101.8 1.47s-75.24-.26-101.8-1.47c-24.56-1.12-37.9-5.22-46.78-8.67-11.76-4.57-20.15-10.03-28.97-18.84-8.82-8.82-14.28-17.21-18.84-28.97-3.45-8.88-7.55-22.22-8.67-46.78-1.21-26.56-1.47-34.53-1.47-101.8s.26-75.23 1.47-101.8c1.12-24.56 5.22-37.9 8.67-46.78 4.57-11.76 10.03-20.15 18.84-28.97 8.82-8.82 17.21-14.28 28.97-18.84 8.88-3.45 22.22-7.55 46.78-8.67 26.57-1.21 34.53-1.47 101.8-1.47m0-45.39c-68.42 0-77 .29-103.87 1.52C121.24 2.74 102.93 7 86.9 13.23c-16.57 6.44-30.62 15.05-44.62 29.06-14 14-22.62 28.05-29.05 44.61C7 102.93 2.74 121.24 1.52 148.05.29 174.92 0 183.5 0 251.92s.29 77 1.52 103.87C2.74 382.6 7 400.91 13.23 416.94c6.44 16.57 15.05 30.62 29.06 44.62 14.01 14.01 28.05 22.62 44.62 29.06 16.02 6.23 34.34 10.49 61.15 11.71 26.87 1.23 35.45 1.52 103.87 1.52s77-.29 103.87-1.52c26.81-1.22 45.13-5.48 61.15-11.71 16.57-6.44 30.62-15.05 44.62-29.06 14.01-14.01 22.62-28.05 29.06-44.62 6.23-16.02 10.49-34.34 11.71-61.15 1.23-26.87 1.52-35.45 1.52-103.87s-.29-77-1.52-103.87c-1.22-26.81-5.48-45.13-11.71-61.15-6.44-16.57-15.05-30.62-29.06-44.62-14.01-14.01-28.05-22.62-44.62-29.06C400.91 7 382.6 2.74 355.79 1.52 328.92.29 320.34 0 251.92 0z" />
    <path d="M251.92 122.56c-71.45 0-129.36 57.92-129.36 129.36s57.92 129.36 129.36 129.36 129.36-57.92 129.36-129.36-57.91-129.36-129.36-129.36zm0 213.33c-46.38 0-83.97-37.6-83.97-83.97 0-46.38 37.6-83.97 83.97-83.97s83.97 37.6 83.97 83.97c0 46.38-37.59 83.97-83.97 83.97z" />
    <circle cx="386.4" cy="117.44" r="30.23" />
  </IconWrapper>
);

export default IconInsta;
