import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-bottom: 60px;
`;

const Blurb = () => {
  return (
    <Wrapper>
      <p className="mb-m">
        Molly Perrin creates jewellery and objects for personal and contextual
        commissions from London. Finding poetry in the processes and phenomena
        around jewellery and objects, to create reflective and emotionally
        charged works.
      </p>
      <p>
        The visual research from these led to new jewellery label{" "}
        <a href="https://www.instagram.com/occur_jewellery/">OCCUR</a>.
      </p>
    </Wrapper>
  );
};

export default Blurb;
