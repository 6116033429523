import React from "react";
import styled from "styled-components";

interface LogoProps {
  width?: string;
  color?: string;
  className?: string;
}
const LogoWrapper = styled("svg")`
  width: calc(180px + 3vw);
  margin-bottom: 120px;
`;

const Logo = ({ color = "var(--primary)", width, className }: LogoProps) => (
  <LogoWrapper
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 274.65 20.39"
    width={width}
    fill={color}
    className={className}
  >
    <path fill="currentColor" d="M9.78 12.32l.01-.03-4.46-8.38z" />
    <path d="M5.33 3.91L3.36.21H0v19.96h2.39V3.64l7.34 12.91 1.25-2.2-.05.08zM13.33 5.7l1.24 2.32 2.48-4.38v16.53h2.39V.21h-3.16zM64.05.21h-2.39v19.96h11.35v-2.09h-8.96zM85.72.21h-2.39v19.96h11.35v-2.09h-8.96zM110.24 8.46L105.15.21h-2.82l6.58 10.51v9.45h2.39v-9.45L118.1.21h-2.67zM166.67 20.17h11.71v-2.1h-9.32v-7.12h8.55V8.84h-8.55V2.32h9.32V.21h-11.71zM199.17 11.59c.79-.19 1.49-.48 2.08-.88.59-.4 1.06-.87 1.4-1.41.34-.54.58-1.11.74-1.71.16-.6.24-1.23.24-1.9 0-.72-.08-1.37-.23-1.95-.15-.58-.35-1.06-.59-1.43-.24-.38-.54-.7-.9-.97-.36-.28-.71-.48-1.03-.62-.33-.13-.7-.24-1.13-.32-.42-.08-.77-.13-1.04-.15-.27-.02-.58-.03-.91-.03h-7.33v19.96h2.4v-8.32h3.86l5.41 8.32h2.67l-5.64-8.59zm-1.61-1.89c-.37.03-.79.05-1.26.05h-3.44V2.3h4.02c.22 0 .44.01.64.02s.45.05.77.11c.31.06.59.13.84.22s.51.23.79.41c.27.18.5.4.69.65.19.25.34.57.46.95s.18.81.18 1.29c0 .67-.12 1.26-.34 1.75-.23.49-.51.87-.83 1.13-.32.26-.73.46-1.22.6-.5.15-.93.24-1.3.27zM225.12 11.59c.79-.19 1.49-.48 2.08-.88.6-.4 1.06-.87 1.4-1.41.34-.54.58-1.11.75-1.71.16-.6.24-1.23.24-1.9 0-.72-.08-1.37-.23-1.95-.15-.58-.35-1.06-.59-1.43-.24-.38-.54-.7-.9-.97-.36-.28-.71-.48-1.03-.62a6.33 6.33 0 0 0-1.13-.32c-.42-.08-.77-.13-1.05-.15-.27-.02-.58-.03-.91-.03h-7.33v19.96h2.4v-8.32h3.86l5.41 8.32h2.67l-5.64-8.59zm-1.61-1.89c-.36.03-.79.05-1.26.05h-3.44V2.3h4.02c.22 0 .44.01.64.02s.45.05.76.11.59.13.84.22.51.23.79.41c.27.18.5.4.69.65.19.25.34.57.46.95s.18.81.18 1.29c0 .67-.11 1.26-.34 1.75-.23.49-.51.87-.83 1.13-.33.26-.73.46-1.22.6-.49.15-.92.24-1.29.27zM242.78.21h2.39v19.96h-2.39zM274.65.21h-2.39v10.85l2.39-1.75zM262.83 2.42L261.39.21h-3.02v19.96h2.39V3.44l10.87 16.73h2.63L262.8 2.43z" />
    <path d="M3.37.21h-.01l1.97 3.7zM155.18 3.95c-.11-.48-.25-.88-.43-1.22-.17-.34-.4-.64-.69-.92-.29-.28-.56-.5-.82-.67-.26-.17-.58-.32-.95-.45-.37-.12-.69-.22-.96-.28-.27-.06-.6-.11-.98-.14-.39-.03-.68-.05-.88-.05-.2-.01-.46-.01-.76-.01h-6.59v19.96h2.39V2.32H148a12.288 12.288 0 0 1 2.21.18c.42.07.78.19 1.07.35.29.16.57.37.84.63.27.25.47.59.61 1 .14.42.21.9.21 1.44 0 .52-.07.99-.2 1.4-.07.2-.14.39-.22.56-.08.17-.18.32-.27.45-.2.27-.47.5-.81.7-.26.16-.51.26-.75.35v2.2c.41-.09.8-.18 1.13-.31.46-.17.92-.42 1.39-.74.47-.31.85-.69 1.14-1.13.29-.44.54-.99.72-1.64.19-.66.28-1.4.28-2.22-.01-.59-.06-1.11-.17-1.59zM49.93 9.96c0-1.51-.24-2.89-.72-4.15-.48-1.26-1.14-2.31-1.97-3.15-.84-.85-1.83-1.5-2.98-1.96-1.14-.47-2.37-.7-3.7-.7-1.34 0-2.58.24-3.74.72a8.7 8.7 0 0 0-2.98 2.04c-.82.87-1.48 1.95-1.96 3.24-.48 1.28-.71 2.7-.71 4.24 0 1.65.26 3.13.79 4.45.52 1.32 1.23 2.38 2.11 3.2.88.82 1.87 1.44 2.96 1.86 1.1.43 2.24.64 3.44.64.4 0 .79-.03 1.17-.07l-1.08-2.03c-.22 0-.41-.05-.62-.07-.57-.08-1.46-.28-2.36-.7-.72-.38-1.37-.86-1.92-1.48-.63-.71-1.12-1.59-1.49-2.65-.36-1.05-.55-2.2-.55-3.45 0-1.3.2-2.47.6-3.51.4-1.03.93-1.86 1.59-2.47.66-.61 1.38-1.08 2.17-1.4.79-.32 1.61-.48 2.46-.48.67 0 1.34.1 1.99.29.65.19 1.28.5 1.89.93.61.43 1.15.95 1.62 1.57.46.62.84 1.38 1.12 2.3.28.92.42 1.93.42 3.04 0 1.09-.13 2.08-.4 2.99-.26.86-.6 1.59-1.01 2.19l1.15 2.17c.58-.63 1.09-1.34 1.5-2.16.81-1.58 1.21-3.4 1.21-5.44z" />
  </LogoWrapper>
);

export default Logo;
