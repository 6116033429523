import React from "react";
// import { Link } from "gatsby";
import "../components/global.css";
import styled from "styled-components";

import Layout from "../components/layout";
import Logo from "../components/logo";
import Signup from "../components/signup";
// import Background from "../components/background";
// import Image from "../components/Image";
import Waves from "../components/waves";
import SEO from "../components/seo";
import IconEmail from "../components/iconEmail";

import IconInsta from "../components/iconInsta";
import SocialLink from "../components/SocialLink";

const Content = styled.div`
  grid-column: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px;

  @media only screen and (max-width: 768px) {
    padding-bottom: 100px;
  }
`;

const Social = styled.div`
  padding: 12px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Molly Perrin - M.Perrin Jewellery" />
    <Waves />
    <Content>
      <Logo />
      <Signup />
      <Social>
        <SocialLink
          icon={<IconInsta />}
          name="@molly_perrin"
          href="https://www.instagram.com/molly_perrin/"
          target="_blank"
        />
        <SocialLink
          icon={<IconEmail />}
          name="contact@mollyperrin.co.uk"
          href="mailto:contact@mollyperrin.co.uk"
        />
      </Social>
      {/* <Image /> */}
    </Content>
  </Layout>
);

export default IndexPage;
