/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
}

const LayoutWrapper = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  align-items: center;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr auto;
  }
`;

const Layout = ({ children }: Props) => (
  <LayoutWrapper>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => <>{children}</>}
    />
  </LayoutWrapper>
);

export default Layout;
