import React from "react";
import styled from "styled-components";

interface Props {
  icon?: React.ReactNode;
  name?: string;
  href?: string;
  target?: string;
}

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  line-height: 1;
  fill: var(--primary);
  color: var(--primary);
  margin: 0 0 12px 0;
  height: 100%;
  width: 100%;
  text-decoration: none;
  transition: all 120ms ease;

  &:last-child {
    margin: 0;
  }

  &:hover {
    fill: var(--highlight-hover);
    color: var(--highlight-hover);
  }
`;

const IconWrapper = styled.div`
  margin: 0 8px 0 0;
  display: flex;
  align-items: center;
`;

const SocialLink = ({ icon, name, href, target }: Props) => {
  return (
    <Wrapper href={href} target={target}>
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {name && <div>{name}</div>}
    </Wrapper>
  );
};

export default SocialLink;
